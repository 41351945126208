<template lang="pug">
  div
    v-btn(small color='primary' :disabled="disabled" @click="toggle" outlined) Course type

    app-dialog(v-model="showing" title="Candidate course type" width="250")
      template(#dialog-body)
        .candidate-course-type__modal
          app-select(
            :options="courseTypeOptions"
            :value="selectedCourseType"
            @input="selectedCourseType = $event"
          )
      template(#dialog-footer)
        v-spacer
        app-button(color="#f4f4f4" hide-shadow @click.native="toggle").mr-2 Close
        app-button(color="primary" @click.native="$emit('select', {course_type: selectedCourseType})") Save
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'

export default {
  name: 'CandidateCourseType',

  props: {
    courseType: {type: String},
    disabled: {type: Boolean, default: false}
  },

  data: () => ({
    selectedCourseType: '',
    courseTypeOptions: [
      'online',
      'offline'
    ]
  }),

  mixins: [showingMixin],

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue')
  },

  watch: {
    showing(value) {
      if (!value) this.selectedCourseType = ''
      else this.selectedCourseType = this.courseType;
    }
  }
}
</script>

<style lang="scss">
.candidate-course-type__modal {
  padding: 10px 20px;
}
</style>



